import React, {useContext, useState, useEffect} from 'react';
import { useApolloClient } from "@apollo/client";
import {SAVE_NEW_MERCHANT} from 'query'


const MerchantContext = React.createContext(null);

const MerchantProvider = ({children}) => {
  const apolloClient = useApolloClient();

  const saveMerchant = async (inputData) => {
    try {
    let query = await apolloClient.mutate({
      mutation: SAVE_NEW_MERCHANT,
      variables: {input: inputData },
    })
    return query
    }catch(err) {
      console.error(err.message)
      throw err
    }
  }

  return (
    <MerchantContext.Provider 
      value={{
        saveMerchant
      }}
    >
      {children}
    </MerchantContext.Provider>
  )
}


const useMerchant = () => {
  const context = useContext(MerchantContext);
  if (context == null) {
    throw new Error('useStore() called outside of a MerchantProvider?');
  }
  return context;
};

export {MerchantProvider, useMerchant};