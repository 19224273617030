import { gql } from '@apollo/client';

const MERCHANT_ATTRIBUTES = gql`
  fragment standardFeedbackAttributes on StandardFeedback {
    status
    msg
  }
`
export const SAVE_NEW_MERCHANT = gql`
  mutation saveNewMerchant($input:JSON){
    saveNewMerchant(input:$input){
      status,
      msg
    }
  }
`;

