import React, { useState, useCallback, useEffect } from 'react'
import { useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import { useForm } from 'react-hook-form'
import { Form, Button } from 'react-bootstrap'; 
import InputText from 'components/input/InputText'
import Message from 'components/Message/Message';
import {useMerchant} from 'contexts/MerchantProvider'
import { Helmet } from 'react-helmet'


const MerchantRegistration = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [message, setMessage] = useState({type: "", message: ""});
  const [reCapStatus, setReCapStatus] = useState(false)
  const [step, setStep] = useState(0)
  const {register, handleSubmit, formState: { errors } } = useForm({mode: "onChange"});
  const {saveMerchant} = useMerchant()

  const handleReCaptchaVerify = useCallback(async () => {
      if (!executeRecaptcha) {
          setReCapStatus(false)
        console.log('Execute recaptcha not yet available');
        return;
      }
      const token = await executeRecaptcha('yourAction');
      setReCapStatus(true)
      // Do whatever you want with the token
  }, [executeRecaptcha]);

  useEffect(() => {
      handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);
  
  const onSubmit = async (data) => {
    if(reCapStatus === false){
      alert("We can't varify your are a human")
      return
    } 
    try{
      await saveMerchant(data);
      setStep(1)
      setMessage({ type: "success", message: "Your registration has been done."})
    }catch(err){
      setMessage({ type: "danger", message: "Please complete your human verification." })
      console.log(err)
    }
  }
  const renderSuccess = () => (
    <div>
      <h1>Success Submited</h1>
      <p>Thank you for your registration, our team will get intouch with you soon, if you did not recieved our call for 48 hours please WhatsApp or call us at 0165993800 thank you</p>
    </div>
  )

  const renderForm = () => (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputText 
          controlId="formBasicEmail"
          type="text"
          placeholder="Full name*"
          register = {{...register("full_name", { required: true })}}
          requiredText="Please enter your full name"
        />
        {(errors.phone_num && errors.phone_num.type==="required") && <div className="empty-feedback">*Full Name is Required</div>}

        <InputText 
          controlId="formBasicEmail"
          type="text"
          placeholder="Phone Number*"
          register = {{...register("phone_num", { required: true })}}
          requiredText="Please enter your phone number"
        />
        {(errors.phone_num && errors.phone_num.type==="required") && <div className="empty-feedback">*Phone Number is Required</div>}

        <InputText 
          controlId="formBasicEmail"
          type="email"
          placeholder="Email*"
          register = {{...register("email", { required: true })}}
          requiredText="Please enter your email address"
        />
        {(errors.email && errors.email.type==="required") && <div className="empty-feedback">*Email is Required</div>}

        <InputText 
          controlId="formBasicEmail"
          type="text"
          placeholder="Business Name*"
          register = {{...register("business_name", { required: true })}}
          requiredText="Please enter your business name"
        />
        {(errors.business_name && errors.business_name.type==="required") && <div className="empty-feedback">*Business Name is Required</div>}

        <InputText 
          controlId="formBasicEmail"
          textarea={true}
          rows={3}
          placeholder="Business Address*"
          register = {{...register("business_address", { required: true })}}
          requiredText="Please enter your business address"
        />
        {(errors.business_address && errors.business_address.type==="required") && <div className="empty-feedback">*Business Address is Required</div>}

        <InputText 
          controlId="formBasicEmail"
          type="text"
          placeholder="Business Nature*"
          register = {{...register("business_nature", { required: true })}}
          requiredText="Please enter your business nature"
        />
        {(errors.business_nature && errors.business_nature.type==="required") && <div className="empty-feedback">*Business Nature is Required</div>}

        <InputText 
          controlId="formBasicEmail"
          textarea={true}
          rows={3}
          placeholder="please briefly describe your business*"
          register = {{...register("business_description", { required: true })}}
          requiredText="please briefly describe for your business"
        />
        {(errors.business_description && errors.business_description.type==="required") && <div className="empty-feedback">*Briefly describe for you business is Required</div>}

        <Button variant="primary" type="submit">Submit</Button>

        <span>Gaspar uses the contact information you provide to us to contact you about our relevant</span>
      </Form> 
  )

  return (
    <section id="merchant_registration">
      <Helmet>
        <script src="../../assets/js/main.js"></script>
      </Helmet>

      <Message type={message['type']} message={message['message']} close={() => setMessage({ type: "", message: "" })}  />

      <div className="container">
        <div className="row">
          <div className="col-12 col-xl-6">
            <div className="section-1">
              <h5>Become a merchant for FREE</h5>
              <p className="description-1">Automate your order today</p>
              <p className="description-2">Become Bereach merchant now. increase productivity and reduce man power</p>
              <button className="btn btn-find-agent" disabled>Find an agent</button>
              <a className="weblink" target="_blank" href="https://wa.me/60165993800" rel="noreferrer">016-5993800</a>
              <i className="bi bi-telephone"></i>
            </div>
          </div>


          <div className="col-12 col-xl-6">
            <div className="section-2 card">
            {step === 1 ? renderSuccess() : renderForm()}
          </div>
          </div>
        </div>
      </div>
      <svg className="hero-waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28 " preserveAspectRatio="none">
        <defs>
          <path id="wave-path" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
        </defs>
        <g className="wave1">
          <use xlinkHref="#wave-path" x="50" y="3" fill="rgba(255,255,255, .1)" />
        </g>
        <g className="wave2">
          <use xlinkHref="#wave-path" x="50" y="0" fill="rgba(255,255,255, .2)" />
        </g>
        <g className="wave3">
          <use xlinkHref="#wave-path" x="50" y="9" fill="#fff" />
        </g>
      </svg>
    </section>
  )
}

export default MerchantRegistration
