import { ApolloProvider } from '@apollo/client';
import {MerchantProvider}  from 'contexts/MerchantProvider'
import { BrowserRouter } from 'react-router-dom';
import createApolloClient from 'lib/apollo'
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import RouterView from './_router'
import Config from './lib/config'

const App = () => {
  const client = createApolloClient();


  return(

    <ApolloProvider client={client}>
      <BrowserRouter>
        <MerchantProvider>
          <GoogleReCaptchaProvider reCaptchaKey={Config.reCAPTCHA_KEY}>
          <RouterView />
          </GoogleReCaptchaProvider>
        </MerchantProvider>
      </BrowserRouter>
    </ApolloProvider>
  )
}

export default App;
