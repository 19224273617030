import { Helmet } from 'react-helmet'
import Home from 'components/Home'
import About from 'components/About'
import Features from 'components/Features'
import Contact from 'components/Contact'

  
  function App() {
    return (
        <>
    <Helmet>
      <script src="../../assets/js/main.js"></script>
    </Helmet>
            <Home />
            <About />
            <Features />
            <Contact />
        </>
    );
  }
  
  export default App;
  