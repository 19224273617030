import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet'
import PageHeader from 'components/PageHeader'
import PageFooter from 'components/PageFooter'
import LandingPage from 'views/LandingPage'
import MerchantRegistration from 'views/MerchantRegistration'

const RouterView = () => {
  return(
    <>
    <PageHeader />
      <Switch>
        <Route path="/" name="Home" render={props => <LandingPage {...props}/>} exact />
        <Route path="/merchant-registration" name="merchantRegistration" component={MerchantRegistration} />
      </Switch> 
    <PageFooter />
    </>
  )
}


export default RouterView;
