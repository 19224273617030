export default {
    APP_VERSION: "v1.0.0",

    reCAPTCHA_KEY: "6LdW0JocAAAAACFee5NqG-GbVKNG62-Xy3gMpQ63",

    /* LOCAL ORDER PRODUCTION SERVER */

    /* LOCAL TESTING SERVER */
    // GRAPHQL_HTTP: "http://localhost:5005/graphql",
    // GRAPHQL_WS: "ws://localhost:5005/wsql",

    /* ORDER PRODUCTION SERVER */
    GRAPHQL_HTTP: "https://superadmin-server.bereach.co/graphql",
    GRAPHQL_WS: "wss://superadmin-server.bereach.co/wsql",
}
