import { Form } from 'react-bootstrap'; 

export default ({ name, style, textarea, type, rows, placeholder, requiredText, register}) => {

    return (
      <Form.Group className="input-layout">
        {textarea === true ? (
          <Form.Control
          name={name}
          style={style}
          className="input-area"
          as="textarea"
          rows={3}
          placeholder={placeholder}
          {...register}
        />
        ) : (
          <Form.Control
          name={name}
          style={style}
          className="input-text"
          type={type}
          placeholder={placeholder}
          {...register}
        />
        )}
        
      </Form.Group>
      
    )
}