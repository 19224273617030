import React from 'react'
import { Link } from 'react-router-dom'
import landing_3_1 from 'assets/img/about/3-1.png'
import landing_3_2 from 'assets/img/about/3-2.png'
import landing_3_3 from 'assets/img/about/3-3.png'
import landing_3_4 from 'assets/img/about/3-4.png'

const Features = () => {
  return (
    <>
    {/* <section id="features" className="features">
      <div className="container">

      <div className="section-title" data-aos="fade-up">
        <h2>Features</h2>
        <p>Check The Features</p>
      </div>

      <div className="row" data-aos="fade-left">
        <div className="col-lg-3 col-md-4">
          <div className="icon-box" data-aos="zoom-in" data-aos-delay="50">
            <i className="ri-store-line" style={{color: "#ffbb2c"}}></i>
            <h3><a href="">Lorem Ipsum</a></h3>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 mt-4 mt-md-0">
          <div className="icon-box" data-aos="zoom-in" data-aos-delay="100">
            <i className="ri-bar-chart-box-line" style={{color: "#5578ff"}}></i>
            <h3><a href="">Dolor Sitema</a></h3>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 mt-4 mt-md-0">
          <div className="icon-box" data-aos="zoom-in" data-aos-delay="150">
            <i className="ri-calendar-todo-line" style={{color: "#e80368"}}></i>
            <h3><a href="">Sed perspiciatis</a></h3>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 mt-4 mt-lg-0">
          <div className="icon-box" data-aos="zoom-in" data-aos-delay="200">
            <i className="ri-paint-brush-line" style={{color: "#e361ff"}}></i>
            <h3><a href="">Magni Dolores</a></h3>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 mt-4">
          <div className="icon-box" data-aos="zoom-in" data-aos-delay="250">
            <i className="ri-database-2-line" style={{color: "#47aeff"}}></i>
            <h3><a href="">Nemo Enim</a></h3>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 mt-4">
          <div className="icon-box" data-aos="zoom-in" data-aos-delay="300">
            <i className="ri-gradienter-line" style={{color: "#ffa76e"}}></i>
            <h3><a href="">Eiusmod Tempor</a></h3>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 mt-4">
          <div className="icon-box" data-aos="zoom-in" data-aos-delay="350">
            <i className="ri-file-list-3-line" style={{color: "#11dbcf"}}></i>
            <h3><a href="">Midela Teren</a></h3>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 mt-4">
          <div className="icon-box" data-aos="zoom-in" data-aos-delay="400">
            <i className="ri-price-tag-2-line" style={{color: "#4233ff"}}></i>
            <h3><a href="">Pira Neve</a></h3>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 mt-4">
          <div className="icon-box" data-aos="zoom-in" data-aos-delay="450">
            <i className="ri-anchor-line" style={{color: "#b2904f"}}></i>
            <h3><a href="">Dirada Pack</a></h3>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 mt-4">
          <div className="icon-box" data-aos="zoom-in" data-aos-delay="500">
            <i className="ri-disc-line" style={{color: "#b20969"}}></i>
            <h3><a href="">Moton Ideal</a></h3>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 mt-4">
          <div className="icon-box" data-aos="zoom-in" data-aos-delay="550">
            <i className="ri-base-station-line" style={{color: "#ff5828"}}></i>
            <h3><a href="">Verdo Park</a></h3>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 mt-4">
          <div className="icon-box" data-aos="zoom-in" data-aos-delay="600">
            <i className="ri-fingerprint-line" style={{color: "#29cc61"}}></i>
            <h3><a href="">Flavor Nivelanda</a></h3>
          </div>
        </div>
      </div>

    </div>
  </section>


  <section id="counts" className="counts">
    <div className="container">

      <div className="row" data-aos="fade-up">

        <div className="col-lg-3 col-md-6">
          <div className="count-box">
            <i className="bi bi-emoji-smile"></i>
            <span data-purecounter-start="0" data-purecounter-end="232" data-purecounter-duration="1" className="purecounter"></span>
            <p>Happy Clients</p>
          </div>
        </div>

        <div className="col-lg-3 col-md-6 mt-5 mt-md-0">
          <div className="count-box">
            <i className="bi bi-journal-richtext"></i>
            <span data-purecounter-start="0" data-purecounter-end="521" data-purecounter-duration="1" className="purecounter"></span>
            <p>Projects</p>
          </div>
        </div>

        <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
          <div className="count-box">
            <i className="bi bi-headset"></i>
            <span data-purecounter-start="0" data-purecounter-end="1463" data-purecounter-duration="1" className="purecounter"></span>
            <p>Hours Of Support</p>
          </div>
        </div>

        <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
          <div className="count-box">
            <i className="bi bi-people"></i>
            <span data-purecounter-start="0" data-purecounter-end="15" data-purecounter-duration="1" className="purecounter"></span>
            <p>Hard Workers</p>
          </div>
        </div>

      </div>

    </div>
  </section> */}


  <section id="features" className="details">
    <div className="container">

    <div className="section-title" data-aos="fade-up">
        {/* <h2>Features</h2> */}
      </div>

      <div className="row content">
        <div className="col-md-4" data-aos="fade-right">
          <img src={landing_3_1} className="img-fluid" alt="" />
        </div>
        <div style={{padding: "1.5rem 70px 0px 70px "}} className="col-md-8 pt-4" data-aos="fade-up">
          <h3 style={{fontSize: "35px",}}>Scan to order</h3>
          <p style={{fontSize: "22px"}} className="fst-italic">
          The “scan to order” system for allow dine- in customers to order and pay with their mobile. 
          </p>
          <ul>
            <li style={{fontSize: "19px"}}><i className="bi bi-check"></i> Allowing businesses to create e-menu, reducing the need to print menu</li>
            {/* <li><i className="bi bi-check"></i> Duis aute irure dolor in reprehenderit in voluptate velit.</li>
            <li><i className="bi bi-check"></i> Iure at voluptas aspernatur dignissimos doloribus repudiandae.</li>
            <li><i className="bi bi-check"></i> Est ipsa assumenda id facilis nesciunt placeat sed doloribus praesentium.</li> */}
          </ul>
          <p style={{fontSize: "22px"}}>
            This contactless ordering reduces the need for manual order taking and allowing more focus on the food’s preparation.
          </p>

          <Link style={{marginTop: "70px", padding: "20px 30px"}} to="/merchant-registration" className="btn-get-started scrollto">become merchant</Link>

        </div>
      </div>

      <div className="row content">
        <div className="col-md-4 order-1 order-md-2" data-aos="fade-left">
          <img src={landing_3_2} className="img-fluid" alt="" style={{ width: "90%" }} />
        </div>
        <div style={{padding: "1.5rem 70px 0px 70px "}} className="col-md-8 pt-5 order-2 order-md-1" data-aos="fade-up">
          <h3 style={{fontSize: "35px"}}>Self pickup</h3>
          <p style={{fontSize: "22px"}} className="fst-italic">
            The  “self-pickup” ordering service allowing customers to order in advance and pickup at store later. 
          </p>
          <p style={{fontSize: "22px"}}>
            This reduces waiting time for customers and allow kitchen better time management.
          </p>
          <p style={{fontSize: "22px"}}>
            Easier to promote menu online on social media.</p>
          <Link style={{marginTop: "70px", padding: "20px 30px"}} to="/merchant-registration" className="btn-get-started scrollto">become merchant</Link>

        </div>
      </div>

      <div className="row content">
        <div style={{ padding: "1.5rem 0px 0px 0px " }} className="col-md-4" data-aos="fade-right">
          <img src={landing_3_3} className="img-fluid" alt="" />
        </div>
        <div style={{padding: "1.5rem 0px 0px 70px "}} className="col-md-8 pt-5" data-aos="fade-up">
          <h3 style={{fontSize: "35px"}}>Home based food</h3>
          <p style={{fontSize: "22px"}}>We also introduce Home-Based selling homemade foods.</p>
          <ul>
            <li style={{fontSize: "19px"}}><i className="bi bi-check"></i> To help start-up entrepreneurs the system to receive and track order online.</li>
            <li style={{fontSize: "19px"}}><i className="bi bi-check"></i> Allowing businesses to promote their businesses online to a wider audience.</li>
          </ul>
          <Link style={{marginTop: "70px", padding: "20px 30px"}} to="/merchant-registration" className="btn-get-started scrollto">become merchant</Link>

          {/* <p>
            Qui consequatur temporibus. Enim et corporis sit sunt harum praesentium suscipit ut voluptatem. Et nihil magni debitis consequatur est.
          </p>
          <p>
            Suscipit enim et. Ut optio esse quidem quam reiciendis esse odit excepturi. Vel dolores rerum soluta explicabo vel fugiat eum non.
          </p> */}
        </div>
      </div>

      <div className="row content">
        <div className="col-md-4 order-1 order-md-2" data-aos="fade-left">
          <img src={landing_3_4} className="img-fluid" alt="" />
        </div>
        <div style={{padding: "1.5rem 70px 0px 70px "}} className="col-md-8 pt-5 order-2 order-md-1" data-aos="fade-up">
          <h3 style={{fontSize: "35px"}}>Online payment</h3>
          <p style={{fontSize: "22px"}} className="fst-italic">
            One stop online payment solution from S pay Global ( formerly known as Sarawak pay), Boost, FPX , credit card and etc.
          </p>
          <p style={{fontSize: "22px"}}>
            This allow businesses to avoid the hassle and cost to apply all these payment gateway themselves.
          </p>
          <Link style={{marginTop: "70px", padding: "20px 30px"}} to="/merchant-registration" className="btn-get-started scrollto">become merchant</Link>

          {/* <ul>
            <li><i className="bi bi-check"></i> Et praesentium laboriosam architecto nam .</li>
            <li><i className="bi bi-check"></i> Eius et voluptate. Enim earum tempore aliquid. Nobis et sunt consequatur. Aut repellat in numquam velit quo dignissimos et.</li>
            <li><i className="bi bi-check"></i> Facilis ut et voluptatem aperiam. Autem soluta ad fugiat.</li>
          </ul> */}
        </div>
      </div>

    </div>
  </section>
  </>
  )
}

export default Features
