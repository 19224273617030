import React from 'react'

const Contact = () => {
  return (
      <section id="contact" className="contact">
        <div className="container">
          <div className="subscribe-area">
            <div className="row">
              <div className="col-lg-6">
                <div className="subscribe-content mt-45">
                  <h2 className="subscribe-title">
                    WhatsApp us<br/> 
                    <span>For more details</span>
                  </h2>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mt-50">
                  <a className="btn btn-primary whatsapp-btn" 
                    target="_blank" 
                    href="https://wa.me/60165993800">
                    
                    WhatsApp us now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default Contact
