import React from 'react'
import company_logo from 'assets/img/Logo.png'
import play_store from 'assets/img/home/play-store.png'
import app_store from 'assets/img/home/app-store.png'


const PageFooter = () => {
  return (
    <div>
      <footer id="footer">
        <div className="container">
          <div className="footer-widget pb-100">
          <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-8">
                <div className="footer-about mt-50 wow fadeIn">
                  <a className="logo" href="#">
                    <img src={company_logo} alt="logo" width="200px" />
                  </a>
                    <p className="text">In the laundry business since 1990. More than 20 years in the laundry &amp; dry cleaning industry, specialize in all types of garment cleaning processes.</p>
                  
                  <ul className=".application-store">
                    <a href="https://play.google.com/store/apps/details?id=com.bereachorderapp" target="_blank" rel="noreferrer">
                      <img src={play_store} alt="" className="app-store-logo" width="120px" />
                    </a>
                    <a href="https://apps.apple.com/my/app/bereach/id1563347966" target="_blank" rel="noreferrer">
                      <img src={app_store} alt="" className="app-store-logo" width="120px" />
                    </a>
                  </ul>
                </div>
              </div>
              <div className="col-lg-5 col-md-7 col-sm-7">
                <div className="mt-50"></div>
              </div>
              <div className="col-lg-3 col-md-5 col-sm-5">
                  <div className="footer-contact">
                  <div className="footer-title">
                    <h4 className="title">Contact Us</h4>
                  </div>

                  <ul className="contact">
                    <li>+6016 599 3800</li>
                    <li>admin@bereach.co</li>
                    <li>http://bereach.co/</li>
                    <li>A108 Adam Street, New York, NY 535022</li>
                  </ul>
                  </div>
              </div>
            </div>
          </div>

        </div>

        <div className="container">
          <div className="copyright">
            Bootslander Services © 2021
          </div>
        </div>
      </footer>
      
      <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>

    </div>
  )
}

export default PageFooter
