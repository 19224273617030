import React from 'react'
import { Alert } from 'react-bootstrap'

const Message = ({ type, message, close }) => {
  return (
    <div>
      <Alert style={{ position: "absolute", zIndex: "1", width: "600px", margin: "63.5% 58% 25% 5%" }} variant={type} onClick={close}>
        {message}
      </Alert>
    </div>
  )
}

export default Message
