import React from 'react'
import { Link } from 'react-router-dom'
import brand_1 from 'assets/img/about/brand-1.jpeg'
import brand_2 from 'assets/img/about/brand-2.jpeg'
import brand_3 from 'assets/img/about/brand-3.jpeg'
import brand_4 from 'assets/img/about/brand-4.jpeg'


const About = () => {
  return (
    <section id="about" className="about">
      <div className="container-fluid">

        <div className="row">
          <div className="col-xl-5 col-lg-6 video-box d-flex justify-content-center align-items-stretch" data-aos="fade-right">
            {/* <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" className="venobox play-btn mb-4" data-vbtype="video" data-autoplay="true"></a> */}
          </div>

          <div className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5" data-aos="fade-left">
            <h3>For F & B businesses</h3>
            {/* <p>Esse voluptas cumque vel exercitationem. Reiciendis est hic accusamus. Non ipsam et sed minima temporibus laudantium. Soluta voluptate sed facere corporis dolores excepturi. Libero laboriosam sint et id nulla tenetur. Suscipit aut voluptate.</p> */}

            <div className="icon-box" data-aos="zoom-in" data-aos-delay="100">
              <div className="icon"><i className="bx bx-fingerprint"></i></div>
              <h4 className="title"><a href="">Scan to order</a></h4>
              <p className="description">Allowing dine-in customer to order foods and drink with their mobile</p>
            </div>

            <div className="icon-box" data-aos="zoom-in" data-aos-delay="200">
              <div className="icon"><i className="bx bx-gift"></i></div>
              <h4 className="title"><a href="">Self pickup</a></h4>
              <p className="description">Letting customer order online first and pick up at store later.</p>
            </div>

            <div className="icon-box" data-aos="zoom-in" data-aos-delay="300">
              <div className="icon"><i className="bx bx-atom"></i></div>
              <h4 className="title"><a href="">Home based food.</a></h4>
              <p className="description">Assisting small entrepreneurs to bring their business online.</p>
            </div>

            <div className="icon-box" data-aos="zoom-in" data-aos-delay="300">
              <div className="icon"><i className="bx bx-atom"></i></div>
              <h4 className="title"><a href="">Online payment</a></h4>
              <p className="description">Providing businesses with online payment gateway</p>
            </div>

            <div className="icon-box">
              <Link to="/merchant-registration" className="btn-get-started scrollto">become merchant</Link>
            </div>

          </div>
        </div>
      </div>
      <div>
        <div className="row" 
        style={{margin: "auto", padding: "0px var(--bs-gutter-x,.75rem)", background: "linear-gradient(#F8F0FF, #FFF)"}}>
          <div className="col-sm-12 col-md-3 brand-showing" data-aos="fade-up">
            <img class="brand-logo" src={brand_1} alt="" />
          </div>
          <div className="col-sm-12 col-md-3 brand-showing" data-aos="fade-up">
          <img class="brand-logo" src={brand_2} alt="" />
          </div>
          <div className="col-sm-12 col-md-3 brand-showing" data-aos="fade-up">
          <img class="brand-logo" src={brand_3} alt="" />
          </div>
          <div className="col-sm-12 col-md-3 brand-showing" data-aos="fade-up">
          <img class="brand-logo" src={brand_4} alt="" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default About
