import React from 'react'
// import hero_img from 'assets/img/hero-img.png'

const PageHeader = () => {
  
  return(
      <header id="header" className="fixed-top d-flex align-items-center header-transparent">
        <div className="container d-flex align-items-center justify-content-between">

          <div className="logo">
            <h1><a href="/"><span>BeReach</span></a></h1>
          </div>

          <nav id="navbar" className="navbar">
            <ul>
              <li><a className="nav-link scrollto active" href="/#hero">Home</a></li>
              <li><a className="nav-link scrollto" href="/#about">About</a></li>
              <li><a className="nav-link scrollto" href="/#features">Features</a></li>
              <li><a className="nav-link scrollto" href="/#contact">Contact</a></li>
              <li><a className="nav-link scrollto" href="/merchant-registration">Join us</a></li>

            </ul>
            <i className="bi bi-list mobile-nav-toggle"></i>
          </nav>

        </div>
      </header>
  )
}

export default PageHeader